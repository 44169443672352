import styled from "styled-components"

import ChainIcon from "src/ui/icons/chain.svg"

export function IntegrationIcon({
  icon,
  iconComponent,
  size = 56,
}: {
  icon?: string
  iconComponent?: React.ReactNode
  size?: number
}) {
  return (
    <ImageBox>
      <Border />
      {getImageComponent({ icon, iconComponent, size })}
    </ImageBox>
  )
}

function getImageComponent({
  icon,
  iconComponent,
  size,
}: {
  icon?: string
  iconComponent?: React.ReactNode
  size: number
}) {
  if (icon) {
    return <StyledImage src={icon} alt="logo" $size={size} />
  }

  if (iconComponent) {
    return (
      <StaticIconComponent $size={size}>{iconComponent}</StaticIconComponent>
    )
  }

  return <ChainIcon width={size} height={size} />
}

// https://base64.guru/converter/encode/image/svg
const maskSvgToBase64 =
  "PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuOTk5OTkgMC4yNUM5IDAuMjUwMDQxIDQzLjUgMC4yNTAxODMgNDggMC4yNUM1Mi4zNjE5IDAuMjQ5ODIyIDU1Ljc1IDMuNjM3ODggNTUuNzUgOFY0OEM1NS43NSA1Mi4zNjE3IDUyLjM2MTkgNTUuNzQ5OCA0OCA1NS43NUM0My41IDU1Ljc1MDEgMTIuNSA1NS43NTAxIDguMDAwMDEgNTUuNzVDMy42MzgwNyA1NS43NDk4IDAuMjUgNTIuMzYxNyAwLjI1IDQ4VjhDMC4yNSAzLjYzNzg4IDMuNjM4MDcgMC4yNDk4MjIgNy45OTk5OSAwLjI1WiIgZmlsbD0iYmxhY2siIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMC41Ii8+Cjwvc3ZnPgo="
const StaticIconComponent = styled.div<{ $size: number }>`
  mask-image: url(data:image/svg+xml;base64,${maskSvgToBase64});
  mask-position: center;
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
`

const StyledImage = styled.img<{ $size: number }>`
  mask-image: url(data:image/svg+xml;base64,${maskSvgToBase64});
  mask-position: center;
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
  -webkit-mask-image: url(data:image/svg+xml;base64,${maskSvgToBase64});
  -webkit-mask-position: center;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  min-width: ${({ $size }) => $size}px;
`

const ImageBox = styled.div`
  margin: 0;
  position: relative;
  z-index: 1;
`

// https://base64.guru/converter/encode/image/svg
const borderSvgToBase64 =
  "PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuOTk5OTkgMC4yNUM5IDAuMjUwMDQxIDQzLjUgMC4yNTAxODMgNDggMC4yNUM1Mi4zNjE5IDAuMjQ5ODIyIDU1Ljc1IDMuNjM3ODggNTUuNzUgOFY0OEM1NS43NSA1Mi4zNjE3IDUyLjM2MTkgNTUuNzQ5OCA0OCA1NS43NUM0My41IDU1Ljc1MDEgMTIuNSA1NS43NTAxIDguMDAwMDEgNTUuNzVDMy42MzgwNyA1NS43NDk4IDAuMjUgNTIuMzYxNyAwLjI1IDQ4VjhDMC4yNSAzLjYzNzg4IDMuNjM4MDcgMC4yNDk4MjIgNy45OTk5OSAwLjI1WiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLW9wYWNpdHk9IjAuMiIgc3Ryb2tlLXdpZHRoPSIwLjUiIHZlY3Rvci1lZmZlY3Q9Im5vbi1zY2FsaW5nLXN0cm9rZSIvPgo8L3N2Zz4K"

const Border = styled.div`
  background: url(data:image/svg+xml;base64,${borderSvgToBase64});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`
