import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { integrationKeys } from "src/data/integrations/queries/integrationQueryCache"
import {
  OwnerType,
  TFetchIntegrationEntityError400,
  TIntegrationEntities,
  TIntegrationId,
} from "src/data/integrations/types/integrationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

/** Fetch all entities for the integration. */
export function useFetchIntegrationEntities({
  integration,
  orgId,
  ownerType = OwnerType.ORGANIZATION,
  options,
}: {
  integration: TIntegrationId
  orgId: string
  ownerType: OwnerType
  options?: UseQueryOptions<
    {
      entities: TIntegrationEntities
    },
    AxiosError<TFetchIntegrationEntityError400>,
    {
      entities: TIntegrationEntities
    },
    ReturnType<typeof integrationKeys.entities>
  >
}) {
  /** https://api.staging.minut.com/latest/docs/internal#tag/Integrations/paths/~1integrations~1{integration_identifier}~1entities/get */
  async function fetchIntegrationEntities() {
    const response = await minutApiHttpClient.get<{
      entities: TIntegrationEntities
    }>(`${API_DEFAULT}/integrations/${integration}/entities`, {
      params: {
        owner_id: orgId,
        owner_type: ownerType,
      },
    })
    return response.data
  }

  return useQuery({
    queryKey: integrationKeys.entities({ integration, orgId }),
    queryFn: fetchIntegrationEntities,
    ...options,
  })
}
